<template>
  <div>
    <!--Progress Bar-->
    <v-dialog v-model="progress" max-width="290" persistent hide-overlay>
      <v-card color="primary" dark>
        <v-card-text class="text-center">
          Loading
          <v-progress-linear indeterminate color="white" class="mb-0" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-container v-if="!progress" fluid fill-height grid-list-lg>
      <v-layout row wrap>
        <!---->
        <v-flex xs12>
          <v-toolbar flat dense color="secondary" dark>
            <v-icon>fal fa-shopping-cart</v-icon>
            <v-toolbar-title class="pl-2 pr-6">
              Orders
            </v-toolbar-title>
            <export-excel
              :section="2"
              :enabled="true"
              :export-title="'Open Orders Export'"
            />
            <v-spacer />
            <v-btn rounded color="green lighten-1" @click="newOrder()">
              <v-icon small left> fal fa-plus </v-icon>New
            </v-btn>
          </v-toolbar>

          <orders-list :location="false" />
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mixin } from "@/mixins/mixin.js";
import OrdersList from "@/components/OrdersList";
import ExportExcel from "@/components/ExportExcel";

export default {
  name: "Orders",
  mixins: [mixin],
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
      customersStore: state => state.customersStore,
      ordersStore: state => state.ordersStore,
      designsStore: state => state.designsStore
    })
  },
  components: {
    OrdersList,
    ExportExcel
  },
  data() {
    return {
      progress: true,
      dialog: false,
      deleteDialog: false
    };
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.progress = true;

      const user = this.$store.getters["usersStore/getAuthUser"];

      const filters = this.$store.getters["ordersStore/getFilters"];

      let data = "";
      if (filters.manualFilter !== 1) {
        data = {
          fk_customerID: user.fk_customerID,
          fk_userID: user.id,
          customerID: "",
          id: "",
          po: "",
          jobName: "",
          status: "",
          page: 1,
          f_restrictOrdersByUser: user.f_restrictOrdersByUser
        };
      } else {
        data = {
          fk_customerID: user.fk_customerID,
          fk_userID: user.id,
          id: filters.id,
          customerID: filters.customerID,
          po: filters.po,
          jobName: filters.jobName,
          status: filters.status,
          page: filters.page,
          f_restrictOrdersByUser: user.f_restrictOrdersByUser
        };
      }

      this.$store.dispatch("ordersStore/storeFilters", data);

      if (!user) {
        this.$router.push({ name: "logout" });
      } else {
        await this.$store.dispatch("ordersStore/fetchByCustomer", data);
        this.progress = false;
      }
    },

    translateStatus(status) {
      if (status === 1) {
        return "Open";
      }

      return "Closed";
    },

    newOrder() {
      this.$router.push({ name: "orderNew" });
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
